import { ArrowRight } from 'lucide-react'
import styles from './stats.module.css'

export default function Stats() {
  return (
    <section className={styles.statsSection}>
      <div className={styles.statCard}>
        <div className={styles.statContent}>
          <div className={styles.statNumber}>
            40,000
            <span className={styles.plus}>+</span>
          </div>
          <div className={styles.statLabel}>Patients treated</div>
        </div>
        <div className={styles.arrow}>
          <ArrowRight size={24} color="#54A7F9" className={styles.arrowIcon} />
        </div>
      </div>

      <div className={styles.statCard}>
        <div className={styles.statContent}>
          <div className={styles.statNumber}>
            15,000
            <span className={styles.plus}>+</span>
          </div>
          <div className={styles.statLabel}>Surgeries prevented</div>
        </div>
        <div className={styles.arrow}>
          <ArrowRight size={24} color="#54A7F9" className={styles.arrowIcon} />
        </div>
      </div>

      <div className={styles.statCard}>
        <div className={styles.statContent}>
          <div className={styles.statNumber}>
            95
            <span className={styles.percent}>%</span>
          </div>
          <div className={styles.statLabel}>Success rate</div>
        </div>
        <div className={styles.arrow}>
          <ArrowRight size={24} color="#54A7F9" className={styles.arrowIcon} />
        </div>
      </div>
    </section>
  )
}


import React, { useState, useRef, useEffect } from "react";
import { Mail, Phone, Activity, ChevronDown } from "lucide-react";
import styles from "./consultation-form.module.css";

export default function ConsultationForm() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState("");
  const dropdownRef = useRef(null);
  const [formData, setFormData] = useState({
    first_name: "",
    mobile_number: "",
    condition: "",
    acceptTerms: false,
    asset_key:
      "4401f37eb216593ed2bae1efc4e092fe96cd5259eec120aedb9aed7a0dee7706",
  });

  const conditions = [
    "Ankle Pain",
    "Back Pain",
    "Elbow Pain",
    "Hip Pain",
    "Knee Pain",
    "Neck Pain",
    "Shoulder Pain",
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create FormData object
      const apiData = new FormData();
      apiData.append("contact[first_name]", formData.first_name);
      apiData.append("contact[mobile_number]", formData.mobile_number);
      apiData.append(
        "contact[custom_field][cf_primary_tag]",
        formData.condition
      );
      apiData.append("asset_key", formData.asset_key);

      const response = await fetch(
        "https://physiotattva-org.myfreshworks.com/crm/sales/smart_form/create_entity?file_attachments_present=false",
        {
          method: "POST",
          body: apiData,
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        alert("Consultation booked successfully!");
        setFormData({
          first_name: "",
          mobile_number: "",
          condition: "",
          acceptTerms: false,
          asset_key:
            "4401f37eb216593ed2bae1efc4e092fe96cd5259eec120aedb9aed7a0dee7706",
        });
        setSelectedCondition("");
      } else {
        const errorData = await response.json();
        alert(
          `Error submitting form: ${errorData.message || "Please try again."}`
        );
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  const selectCondition = (condition) => {
    setSelectedCondition(condition);
    setFormData((prev) => ({ ...prev, condition }));
    setIsDropdownOpen(false);
  };

  return (
    <section className={styles.formSection}>
      <h2 className={styles.title}>Book Free consultation now!</h2>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formRow}>
          <div className={styles.inputGroup}>
            <label className={styles.label}>Name</label>
            <div className={styles.inputWrapper}>
              <Mail className={styles.icon} size={20} />
              <input
                type="text"
                name="first_name"
                placeholder="Full Name"
                className={styles.input}
                value={formData.first_name}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Phone number</label>
            <div className={styles.inputWrapper}>
              <Phone className={styles.icon} size={20} />
              <div className={styles.phoneInput}>
                <span className={styles.countryCode}>+91</span>
                <input
                  type="tel"
                  name="mobile_number"
                  placeholder="Mobile Number"
                  className={styles.input}
                  value={formData.mobile_number}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Condition</label>
            <div className={styles.inputWrapper} ref={dropdownRef}>
              <Activity className={styles.icon} size={20} />
              <div className={styles.dropdownContainer}>
                <button
                  type="button"
                  className={`${styles.input} ${styles.dropdownButton}`}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <span
                    className={!selectedCondition ? styles.placeholder : ""}
                  >
                    {selectedCondition || "Select Condition"}
                  </span>
                  <ChevronDown size={20} className={styles.dropdownIcon} />
                </button>
                {isDropdownOpen && (
                  <div className={styles.dropdown}>
                    {conditions.map((condition) => (
                      <div
                        key={condition}
                        className={styles.dropdownItem}
                        onClick={() => selectCondition(condition)}
                      >
                        {condition}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.checkbox}>
          <input
            type="checkbox"
            id="terms"
            name="acceptTerms"
            className={styles.checkboxInput}
            checked={formData.acceptTerms}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="terms" className={styles.checkboxLabel}>
            I accept terms and conditions
          </label>
        </div>

        <button type="submit" className={styles.submitButton}>
          Book Free Consultation
        </button>
      </form>
    </section>
  );
}

import { ArrowRight } from "lucide-react";
import styles from "./hero.module.css";

export default function Hero() {
  const handleBooking = () => {
    window.open(
      "https://app.physiotattva247.com/appointment-date-time?consultationTypeId=5&mode=online&selectedLocation=&selectedCenter=",
      "_blank"
    );
  };

  return (
    <>
      <section className={styles.hero}>
        <div className={styles.content}>
          <h1 className={styles.title}>Online Physiotherapy</h1>
          <p className={styles.description}>
            Transforming Musculoskeletal (MSK) Health Through Advanced Digital
            Therapy, Strength and Conditioning, and Return-to-Sport Programs.
          </p>
          <button className={styles.button} onClick={handleBooking}>
            Book Free Consultation
            <ArrowRight size={20} />
          </button>
        </div>
        <div className={styles.phones}>
          <div className={styles.phoneContainer}>
            <img
              src="https://physiotattava-website.s3.eu-central-1.amazonaws.com/iPhone+15+Pro+Portrait+Mockup+(4).png"
              alt="Doctor video call interface"
              className={styles.phoneMain}
            />
          </div>
          <div className={styles.phoneContainer}>
            <img
              src="https://physiotattava-website.s3.eu-central-1.amazonaws.com/Home+%26+Smart+Health+Metrics.png"
              alt="Exercise tracking interface"
              className={styles.phoneSecondary}
            />
          </div>
        </div>
      </section>
    </>
  );
}

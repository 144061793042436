import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import MainContent from "./components/MainContent/MainContent";
import Footer from "./components/Footer/Footer";
import styles from "./App.module.css";
import useAnalytics from "./hooks/useAnalytics";
import BookAppointment from "./components/payment";
import Head from "./components/Head/hero";
import Stats from "./components/Head/stats";
import ConsultationForm from "./components/Head/consultation-form";


// Layout component with conditional rendering
const Layout = ({ children }) => {
  const location = useLocation();
  const isBookingPage = location.pathname === "/book-appointment";

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflowX: "hidden",
        position: "relative",
      }}
      className={styles.app}
    >
      {!isBookingPage && <Header />}
      {children}
      {!isBookingPage && <Footer />}
    </div>
  );
};

// Rest of your components remain the same
const HomePage = () => {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView("/");
  }, []);

  return (
    <>
      <div className={styles["fade-in"]}>

        <Head />
      <Stats />
      <ConsultationForm />
      </div>
      <div className={styles["fade-in"]}>
        <MainContent />
      </div>
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />
        <Route
          path="/book-appointment"
          element={
            <Layout>
              <BookAppointment />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
